<template>
  <div class="content">
    <div class="flex gap-8">
      <div class="bg-gray-100 border border-gray-200 px-4 py-3 rounded-sm">
        <button-select
          v-model="tab"
          :options="tabs"
          size="sm"
          class="flex-col gap-2"
        />
      </div>

      <div class="flex-1">
        <component :is="tabComponent"></component>
      </div>
    </div>
  </div>
</template>

<script>
const ButtonSelect = () => import('@/components/global/ButtonSelect.vue');
const CandleLayerCache = () => import('@/components/developer/CandleLayerCache.vue');
const CandleTemplates = () => import('@/components/developer/CandleTemplates.vue');
const Dropdowns = () => import('@/components/developer/Dropdowns.vue');
const LabelPrinters = () => import('@/components/developer/LabelPrinters.vue');

export default {
  name: 'Developer',
  components: {
    ButtonSelect,
    CandleLayerCache,
    CandleTemplates,
    Dropdowns,
    LabelPrinters,
  },
  computed: {
    tabComponent() {
      return this.tabs[this.tab].component;
    },
  },
  data() {
    return {
      tab: 0,
      tabs: [
        { text: 'Candle Templates', value: 0, component: CandleTemplates },
        { text: 'Candle Layer Cache', value: 1, component: CandleLayerCache },
        { text: 'Dropdown', value: 2, component: Dropdowns },
        { text: 'Label Printers', value: 3, component: LabelPrinters },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
